import { useCampaigns } from "@/composables/useCampaigns";
import { clearCookie } from "@/utils";
import { MIDDLEWARES, ROUTES } from "@/utils/consts";
import { errorMessages } from "@/utils/consts/errorMessages";
import { toast } from "@/utils/toastHandler";
import Nprogress from "nprogress";
import type { Router } from "vue-router";
import { useCookies } from "vue3-cookies";

const SETUP_ROUTE_GUARD = (router: Router) => {
  const { cookies } = useCookies();
  const { setCampaignSource } = useCampaigns();

  router.beforeEach((to, from, next) => {
    Nprogress.start();

    const queryString = to.fullPath.split("?")[1];
    const path = to.fullPath.split("?")[0];

    if (path === ROUTES.INDEX.path) {
      queryString
        ? setCampaignSource(queryString.split("=")[1] as string)
        : null;
    }

    const token = cookies.get("token");
    const expiresAt = cookies.get("expiresAt") ?? "";

    let expiryTime: Date | number = 0;
    if (expiresAt) {
      const [dateString, timeString] = expiresAt.split(" ");
      const [year, month, day] = dateString.split("-").map(Number);
      const [hour, minute, second] = timeString.split(":").map(Number);
      expiryTime = new Date(
        Date.UTC(year, month - 1, day, hour, minute, second)
      );
    }

    const middlewares = to.meta.middlewares as {
      [middleware: string]: boolean;
    };
    let isRouteProtected = middlewares?.[MIDDLEWARES.PROTECTED] ?? false;
    if (!isRouteProtected) {
      return next();
    }

    if (
      isRouteProtected &&
      token &&
      (typeof expiryTime === "number"
        ? Date.now() >= expiryTime
        : Date.now() >= expiryTime.getTime())
    ) {
      toast.error(errorMessages.server[401]);
      clearCookie();
      return next({ path: ROUTES.LOGIN.path });
    } else if (isRouteProtected && !token) {
      return next({ path: ROUTES.LOGIN.path });
    }

    return next();
  });

  router.afterEach(() => {
    Nprogress.done();
  });

  router.onError((error, to) => {
    if (
      error.message.includes("Failed to fetch dynamically imported module") ||
      error.message.includes("Importing a module script failed")
    ) {
      if (!to?.fullPath) {
        window.location.reload();
      } else {
        window.location.href = to.fullPath;
      }
    }
  });
};

export default SETUP_ROUTE_GUARD;
