<script setup lang="ts">
import { onMounted } from "vue";

onMounted(async () => {
  // Preload the WASM file
  await fetch("/rive.wasm")
    .then(() => {})
    .catch(() => {});
});
</script>

<template>
  <router-view></router-view>
</template>

<style scoped></style>
