import { WalletCategory } from "@/shared/enums/wallets";
import { IWallet } from "@/shared/types/wallets";
import { AxiosResponse } from "axios";
import { DefineComponent } from "vue";

export interface quickLink {
  icon: string | any;
  two_tone_icon: string | any;
  title: string;
  sub_title: string;
  name: string;
}

export interface CryptoNetwork {
  name: string;
}

export enum quickLinksType {
  CRYPTO = "CRYPTO",
  FIAT = "FIAT",
}

export type QuickLinksStoreOptions = {
  selectedNetwork: CryptoNetwork | null;
  allCrypto: IWallet[];
  userAccValidation: {
    type: "email" | "phone";
    message: string;
  } | null;
  allFiatCoin: IWallet[];
  quickLinkType: WalletCategory | string;
  deposit: {
    step: number;
    coins: IWallet[];
    fiat: {
      amount: number;
      to: string;
      transactionFee: string;
      accountNumber: string;
      totalPayable: string;
      wallet: {
        name: string;
        image: string;
      };
      date: string;
    };
    selectedWallet: IWallet | null;
    walletAddress: string | null;
  };
  withdraw: {
    coins: IWallet[];
    step: number;
    selectedWallet: IWallet;
    amount: number;
    walletAddress: string | null;
    verification: {
      email?: boolean;
      mobile?: boolean;
    };
    fiat: {
      amount: string;
      from: string;
      to: string;
      bank: {
        name: string;
        image: string;
      };
      accountNumber: string;
      date: string;
    };
  };
  trade: {
    coins: IWallet[];
    activeTab: number;
    payCoin: IWallet | null;
    sell: {
      payCoin: IWallet | null;
      receiveWallet: IWallet | null;
      payAmount: number | null;
      receiveAmount: number | null;
    };
    buy: {
      payCoin: IWallet | null;
      receiveWallet: IWallet | null;
      payAmount: number | null;
      receiveAmount: number | null;
    };
    step: number;
    swap: {
      payAmount: number;
      receiveAmount: number;
      payCoin: IWallet | null;
      receiveWallet: IWallet | null;
    };
  };
};

export enum Tradetype {
  BUY = "BUY",
  SELL = "SELL",
}

export enum WithdrawStatusOptions {
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
}

export interface IBeneficiary {
  id: number;
  bank: {
    bankName: string;
    accountName: string;
    image: string;
    accountNumber: string;
  };
}

export interface IAxiosApiResponse<T = any> extends AxiosResponse {
  data: {
    pagination?: any;
    status: number;
    data: T;
    message: string;
  };
}

export interface ILoginResponse {
  session: {
    token: string;
    expiresAt: string;
  };
}

export interface IProfileResponse {
  profile: {
    id: string;
    email: string | null;
    phone: string | null;
    username: string;
    firstName: string | null;
    lastName: string | null;
    passwordUpdatedAt: string | null;
    profilePercentage: number;
    createdAt: string;
  };
  securityStatus: {
    isEmailVerificationActive: true;
    isGoogle2FaActive: false;
    isPhoneVerificationActive: false;
  };
}

export type CustomErrorResponse = {
  message: string;
  errors?: any;
};

export interface ICryptoWithdrawForm {
  amount: number | string;
  address: string;
  memo: string;
  network: string;
  asset: string;
}

export interface ICryptoWithdrawSummary {
  amount: number;
  address: string;
  network: string;
  asset: string;
  sessionRef: string;
  fee: number;
  security: ISecurityOptions;
  warnings: [];
  otp?: string;
}

export interface IResetPasswordForm {
  channel: string;
  identifier: string;
  newPassword: string;
  verificationToken: string;
}

export interface ISignUpForm {
  identifier: string;
  channel: string;
  password: string;
  validationToken: string;
}

export interface IResetTokenForm {
  identifier: string;
  gRecaptchaResponse: string;
  channel?: string;
}

export interface ISwapStoreData {
  allowedDestinationAssets: {
    all: string[];
    buy: string[];
    sell: string[];
  };
  allowedSourceAssets: {
    all: string[];
    buy: string[];
    sell: string[];
  };
  from: string;
  minimumAmount: string;
  offer: number;
  rate: {
    usdRate: number;
    fiatAsset: IAssetInfo;
  };
  referencePrices: {};
  to: string;
}

export interface  IWithdrawalNetwork {
  isAvailable: boolean;
  network: string;
  withdrawalFee: number;
  maximumAllowedWithdrawalValue: number;
  minimumAllowedWithdrawalValue: number;
  requiresMemo: boolean;
}

export interface IAvailableCrypto extends IWallet {
  networks: IWithdrawalNetwork[];
}

export enum ValidationRequiredCode {
  EMAIL_VERIFICATION_REQUIRED = "EMAIL_VERIFICATION_REQUIRED",
  PHONE_VERIFICATION_REQUIRED = "PHONE_VERIFICATION_REQUIRED",
  KYC_REQUIRED = "KYC_REQUIRED",
}

export enum CONFIRM_EMAIL_STATUS {
  PENDING = "pending",
  CONFIRMED = "confirmed",
}

export interface IExchangeRate {
  asset: string;
  margin: number;
  buyRate: number;
  sellRate: number;
}

export interface IExchangeRateApiResponse {
  exchangeRates: any;
  nativeRates: {
    ngnUsd: number;
    rates: IExchangeRate[];
  };
}

export interface IExchangeRateSwap {
  amount: number;
  offer: number;
}

export interface IExchangeRateSwapData {
  amount: number;
  sourceAsset: string;
  destinationAsset: string;
}

export enum KYC_STATUS {
  PENDING = "PENDING",
  VERIFIED = "VERIFIED",
  REJECTED = "REJECTED",
  DUPLICATE = "DUPLICATE",
  REVIEW_REQUIRED = "REVIEW-REQUIRED",
}

export interface IAssetInfo {
  name: string;
  category: string;
  code: string;
  symbol: string;
  imageUrl: string;
  decimalPlaces: number;
}

export interface ISwapWallet {
  code: string;
  name: string;
  symbol: string;
  imageUrl: string;
  decimalPlaces: number;
  balance: number;
  balanceString: string;
  altBalanceAsset: string;
  altBalance: number;
  altBalanceString: string;
  totalBalance: number;
  totalBalanceString: string;
  altTotalBalance: number;
  altTotalBalanceString: string;
  hasPendingSwap: boolean;
  validPairs: Omit<ISwapWallet, "validPairs">[];
}

export interface ISwapInitResponse {
  allowedAssets: {
    all: ISwapWallet[];
    buy: ISwapWallet[];
    sell: ISwapWallet[];
  };
  referencePrices: {
    baseAsset: IAssetInfo;
    rates: {
      asset: IAssetInfo;
      value: number;
    }[];
  };
}

export interface ISwapSummaryDetails {
  readonly amount: number;
  destination: {
    asset: IAssetInfo;
    balance: number;
    balanceAfter: number;
  };
  id: string;
  readonly offer: number;
  rate: {
    readonly usdRate: number;
    fiatAsset: {
      name: string;
      category: string;
      code: string;
      symbol: string;
      imageUrl: string;
      decimalPlaces: number;
    };
  };
  readonly sessionRef: string;
  source: {
    asset: {
      name: string;
      category: string;
      code: string;
      symbol: string;
      imageUrl: string;
      decimalPlaces: number;
    };
    readonly balance: number;
    readonly balanceAfter: number;
  };
}

export interface IConfirmPayload {
  amount: number;
  sessionRef: string;
}

export enum ITradeAction {
  SELL = "sell",
  BUY = "buy",
  SWAP = "swap",
}

export interface ITradeActionTab {
  title: ITradeAction;
  description: string;
  color: string;
  icon: DefineComponent;
}

export interface IFiatWithdrawalType {
  name: string;
  id: number;
}

export enum VerificationChannel {
  WHATSAPP = "whatsapp",
  SMS = "sms",
  EMAIL = "email",
}

export interface ISecurityOptions {
  emailOtp: boolean | string;
  google2FaToken?: boolean | string;
  phoneOtp: boolean | string;
  google2fa?: string;
}
